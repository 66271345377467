<template>
  <div class="text-center flex h-screen px-10">
    <div class="m-auto">
      <img class="h-20 w-20 m-auto mb-10" src="@/assets/logo.svg" />
      <h1 class="font-bold text-4xl">Welcome to ExamRevolution Admin!</h1>
      <br />
      <h2 class="text-sm">Click an option in the sidebar to get started.</h2>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
