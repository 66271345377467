import { createApp } from "vue";
import { VueEmailPlugin } from "vue-email";
import App from "./App.vue";
import router from "./router";
import "../src/css/output.css";
// import VueGtag from "vue-gtag";

import * as dotenv from "dotenv";

dotenv.config();

createApp(App).use(router).use(VueEmailPlugin).mount("#app");
// createApp(App).use(router).mount("#app");
