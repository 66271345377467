<template>
  <div class="min-h-screen bg-green-50 w-full">
    <div
      class="w-full h-10 bg-yellow-300 text-center pt-2 font-bold shadow-sm"
      v-if="environment"
    >
      You are viewing this site in the {{ environment }} environment.
    </div>

    <body class="min-h-screen">
      <router-view class="relative" />
    </body>
  </div>
</template>

<script>
import { firebaseConfig } from "./firebase/firebase-config";

export default {
  components: {},
  methods: {
    env() {
      switch (firebaseConfig.projectId) {
        case "er-dev-7164a":
          return "development";
        case "er-staging-e4ba3":
          return "staging";
      }
      return null;
    },
  },
  data() {
    return {
      environment: null,
    };
  },
  created() {
    this.environment = this.env();
  },
};
</script>

<style></style>
